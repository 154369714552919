import React from "react"
import { isMobile } from 'react-device-detect';

/************************************************
* ROUTE VPP                                     *
*************************************************/
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Products
import Product from "../pages/Products"
import Categories from "../pages/Categories"
import Carousels from "../pages/Carousels"
import ListAccount from "../pages/ListAccount"
import ListOder from "../pages/LIstOder"
import ListTempBill from "../pages/ListTempBill"
import OderManager from "../pages/OderManager"

//Scan
import PreparePackageInventory from "../pages/ScanInventory/PreparePackage"
import ScanCheckPackageInventory from "../pages/ScanInventory/ScanCheckPackageInventory"
import GetScanInventory from "../pages/ScanInventory/get-scan-inventory"
import ScanFinished from "../pages/ScanInventory/Finished"
import ScanInPackageInventory from "../pages/ScanInventory/ScanInPackageInventory"
import ScanToShipperInventory from "../pages/ScanInventory/ScanToShipperInventory"
import ScanWaitingPayInventory from "../pages/ScanInventory/ScanWaitingPayInventory"
import ScanWaitingExportVATInventory from "../pages/ScanInventory/ScanWaitingExportVATInventory"
import ListWaitingExportVATInventory from "../pages/ScanInventory/ListWaitingExportVATInventory"

//List Account
import ListDetailOder from "../pages/ListDetailOder"
import ScanOder from "../pages/ScanOder"
import ProductDetail from "../pages/Products/product-detail-mobile";
import ProductListMobile from "../pages/Products/product-list-mobile";
import ProductCreate from "../pages/Products/product-create-mobile"

// Price Delivery
import PriceDelivery from "../pages/PriceDelivery";

import CategoryProducts from '../pages/CategoryProducts';

import Dashboard from "../pages/Dashboard"
const userRoutes = [
  { path: "/product", component: Product },
  { path: "/product-list-mobile", component: ProductListMobile },
  { path: "/category", component: Categories },
  { path: "/carousel", component: Carousels },
  { path: "/list-account", component: ListAccount },
  { path: "/status-oder", component: ListOder },
  { path: "/oder-manager", component: OderManager },
  { path: "/oder-manager/:slug", component: OderManager },
  { path: "/list-detail-oder", component: ListDetailOder },
  { path: "/list-detail-oder/:slug", component: ListDetailOder },
  { path: "/scan-oder", component: ScanOder },
  { path: "/product/detail/:slug", component: ProductDetail },
  { path: "/product/create", component: ProductCreate },
  { path: "/category-products", component: CategoryProducts },
  { path: "/scan-packing-inventory", component: PreparePackageInventory },
  { path: "/scan-delivery-inventory", component: ScanCheckPackageInventory },
  { path: "/get-scan-inventory", component: GetScanInventory },
  { path: "/scan-in-package-inventory", component: ScanInPackageInventory },
  { path: "/scan-to-shipper-inventory", component: ScanToShipperInventory },
  { path: "/scan-not-pay-inventory", component: ScanWaitingPayInventory },
  { path: "/scan-waiting-export-vat-inventory", component: ScanWaitingExportVATInventory },
  { path: "/list-waiting-export-vat-inventory", component: ListWaitingExportVATInventory },
  { path: "/scan-finished", component: ScanFinished },
  { path: "/delivery-code", component: PriceDelivery },
  { path: "/dashboard", component: Dashboard },
  { path: "/list-temp-bill", component: ListTempBill },

];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }