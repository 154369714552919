import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"
import { setToken } from '../../../helpers/api_helper'
//Include Both Helper File with needed methods
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"


function* loginUser({ payload: { user, navigate } }) {
  try {

    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

      const { data: { token, user: userSuccess } } = yield call(postJwtLogin, {
        username: user.username,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(userSuccess))
      localStorage.setItem("authToken", token)
      setToken()
      yield put(loginSuccess({ token, userSuccess }))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {

      const { data: { token, user } } = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      yield put(loginSuccess({ token, user }))
    }
    navigate("/")
  } catch (error) {
    console.log(error)

    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { navigate } }) {
  try {
    localStorage.removeItem("authUser")
    navigate("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, navigate } }) {
  try {
    const response = yield call(postSocialLogin, data)
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    navigate("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
