import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./pagination.scss";

const Pagination = (props) => {
  const { pageActive, total, perPage, handleChangePage } = props;
  const totalPage = perPage ? Math.ceil(total / perPage) : 0;
  const [page, setPage] = useState(1);

  useEffect(() => {
    console.log(pageActive)
    if (pageActive) {
      setPage(pageActive);
    }
  }, [pageActive]);

  return (
    <div className="pagination">
      <h4 className="page-title mb-0 font-size-18">Trang</h4>
      <input
        className="pagination_input"
        value={page}
        onChange={(e) => {
          if (e.target.value >= 0)
          {
            handleChangePage(e.target.value);
            setPage(e.target.value);
          }
        }}
      />
      <h4 className="page-title mb-0 font-size-18">{`/ ${totalPage}`}</h4>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  pageActive: PropTypes.number,
  total: PropTypes.number,
  perPage: PropTypes.number,
  handleChangePage: PropTypes.func,
};
