import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { Col, Row, Button } from "reactstrap";
import { RenderAmount } from "../../utils";
import { useFormContext } from "react-hook-form";
import Loading from "../../components/Loading/loading";
import "./DeliveryInfo.scss";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ModalConfirm from "../../components/Modal/modalConfirm";
import { useNavigate } from 'react-router'
import { get } from "../../helpers/api_helper";
import moment from 'moment';
const INVOICE_TYPE = {
  DIRECT: 'DIRECT',
  E_VAT_INVOICE: 'E_VAT_INVOICE',
}


const DeliveryInfo = forwardRef((props, ref) => {
  const {
    billId,
    listProduct,
    status,
    handleCancel,
    statusOder,
    handleUpdateStatus,
    loading,
    handleConfirmed,
    handlePreparing,
    handleDeliver,
    handleCheckPackage,
    handlePackageIn,
    handleWaitingPay,
    handleWaitingExportVAT,
    handleFinish,
    printRef,
    exportRef,
    getListCostDelivery,
    isShowShipMethod,
    sendBillMail,
    statusAllowUpdate,
    cancelReason,
  } = props;
  const navigate = useNavigate()
  const {
    getValues,
    watch,
  } = useFormContext();
  const [histories, setHistories] = useState([]);
  const [costDeliver, setCostDeliver] = useState(getValues().costDeliver);
  const [invoiceType, setInvoiceType] = useState(getValues().invoiceType);
  const [vatPercent, setVatPercent] = useState(getValues().vatPercent);
  const [deposit, setDeposit] = useState(getValues().deposit);
  useEffect(() => {
    setCostDeliver(getValues().costDeliver);
  }, [watch('costDeliver')]);

  useEffect(() => {
    setInvoiceType(getValues().invoiceType);
  }, [watch('invoiceType')]);

  useEffect(() => {
    setVatPercent(getValues().vatPercent);
  }, [watch('vatPercent')]);

  useEffect(() => {
    setDeposit(getValues().deposit);
  }, [watch('deposit')]);
  useEffect(() => {
    if (billId) {
      get(`/bills/history/${billId}`)
        .then((data) => {
          const listHistories = data.filter(e => e.type === "DELIVERY_STATUS" && e.deliveryName === 'GHTK').map(e => ({
            status: e.info.statusText,
            createdAt: e.info.modified,
            deliveryId: e.deliveryId
          }))
          setHistories(listHistories)
        })
    }
  }, []);

  const unitTotal = () => {
    let total = 0;
    if (listProduct.length === 0) return 0;
    listProduct.map((item) => {
      if (item.cost) {
        total += Number(item.cost);
      }
    });
    return Number(total);
  };

  const totalProduct = () => {
    let total = 0;
    if (listProduct.length === 0) return 0;
    listProduct.map((item) => {
      if (item.price && item.quantity) {
        total += Number(item.quantity) * (Number(item.price) - Number(item.discountPerQuantity || 0));
      }
    });
    return Number(total);
  };

  const vatValue = () => {
    let totalPrice = totalProduct();
    return Math.round(totalPrice * vatPercent / 100)
  }
  const total = () => {
    let totalPrice = totalProduct();
    if (invoiceType === INVOICE_TYPE.E_VAT_INVOICE) {
      totalPrice += vatValue();
    }

    if (costDeliver) {
      totalPrice += +costDeliver;
    }
    return Number(totalPrice);
  };

  const restTotal = () => {
    return total() - deposit;
  }
  useImperativeHandle(ref, () => ({
    total,
    totalProduct,
    restTotal,
  }));

  const renderSendMailButton = () => {
    return <Button
      className="btn btn-warning"
      style={{ marginRight: 5 }}
      type="button"
      onClick={sendBillMail}
    >
      Gửi Hoá đơn Mail
    </Button>
  }
  const renderExportExcelButton = () => {
    return <Button style={{ marginRight: 5 }} className="btn btn-warning waves-effect waves-light" onClick={() => {
      exportRef.current.exportToCSV();
    }}>Xuất Excel</Button>
  }

  const renderPrintBillButton = () => {
    return <ReactToPrint
      trigger={() => (
        <Button
          className="btn btn-primary waves-effect waves-light"
          style={{ marginRight: 5 }}
          type="button"
        >
          In
        </Button>
      )}
      content={() => printRef.current}
    />
  }

  const renderSetDeliveryProviderButton = () => {
    return <Button
      className="btn btn-danger"
      style={{ marginRight: 5 }}
      type="button"
      onClick={getListCostDelivery}
    >
      Chọn đơn vị vận chuyển
    </Button>
  }

  const renderSetConfirmAndPrintBillButton = () => {
    return <ReactToPrint
      content={() => printRef.current}
      onAfterPrint={() => navigate('/status-oder')}
    >
      <PrintContextConsumer>
        {({ handlePrint }) => (
          <Button
            className="btn btn-success waves-effect waves-light"
            style={{ marginRight: 5 }}
            type="button"
            onClick={() => handleConfirmed(handlePrint)}
          >
            Xác nhận Và In
          </Button>
        )}

      </PrintContextConsumer>
    </ReactToPrint>
  }

  const renderSetPreparingButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handlePreparing}
    >
      Chuẩn bị hàng
    </Button>
  }


  const renderSetCheckPackageButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handleCheckPackage}
    >
      Kiểm hàng
    </Button>
  }

  const renderSetPackageInButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handlePackageIn}
    >
      Đóng gói
    </Button>
  }

  const renderSetWaitingPayButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handleWaitingPay}
    >
      Đợi thanh toán
    </Button>
  }

  const renderSetWaitingExportVATButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handleWaitingExportVAT}
    >
      Đợi xuất HD Đỏ
    </Button>
  }

  const renderSetWaitPickupButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handleDeliver}
    >
      Đợi lấy hàng
    </Button>
  }

  const renderSetFinishButton = () => {
    return <Button
      className="btn btn-success waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handleFinish}
    >
      Hoàn thành
    </Button>
  }

  const renderSetCancelButton = () => {
    return <Button
      className="btn btn-warning waves-effect waves-light"
      style={{ marginRight: 5 }}
      type="button"
      onClick={handleCancel}
    >
      Hủy đơn
    </Button>
  }

  const renderSetEditButton = () => {
    return <Button
      className="btn btn-info waves-effect waves-light"
      type="submit"
      style={{ marginRight: 5 }}
      onClick={handleUpdateStatus}
    >
      Sửa đơn
    </Button>
  }
  return (
    <div className="delivery-info">
      <Row>
        <Col>
          <h5>Thông tin vận chuyển</h5>
          {histories?.length > 0 ? <h6>Mã đơn: <strong>{histories[0].deliveryId}</strong></h6> : ''}
          <ul>
            {histories &&
              histories.map((item, index) => (
                <li><b>{moment(item.createdAt).format('HH:mm:ss DD-MM-YYYY')}</b>: {item.status}</li>
              ))}
          </ul>

        </Col>
        <Col>
          <Row className="justify-content-between mt-4">
            <p className="text-right col-md-10">Tổng tiền vốn sản phẩm:</p>
            <p className="col-md-2 text-right">
              {<RenderAmount price={unitTotal()} />}
            </p>
          </Row>
          <Row className="justify-content-between">
            <p className="text-right col-md-10">Tổng tiền sản phẩm:</p>
            <p className="col-md-2 text-right">
              {<RenderAmount price={totalProduct()} />}
            </p>
          </Row>
          {
            invoiceType === INVOICE_TYPE.E_VAT_INVOICE &&
            <Row className="justify-content-between">
              <p className="text-right col-md-10">VAT ({vatPercent}%):</p>
              <p className="col-md-2 text-right">
                {<RenderAmount price={vatValue()} />}
              </p>
            </Row>
          }

          <Row className="justify-content-between">
            <p className="text-right col-md-10">Phí vận chuyển:</p>
            <p className="col-md-2 text-right">
              {<RenderAmount price={costDeliver || 0} />}
            </p>
          </Row>
          <Row className="justify-content-between">
            <p className="text-right col-md-10">Tổng cộng:</p>
            <p className="col-md-2 text-right">
              {<RenderAmount price={total()} />}
            </p>
          </Row>
          {
            deposit > 0 ?
              <>
                <Row className="justify-content-between">
                  <p className="text-right col-md-10">Ứng trước:</p>
                  <p className="col-md-2 text-right">
                    {<RenderAmount price={deposit} />}
                  </p>
                </Row>
                <Row className="justify-content-between">
                  <p className="text-right col-md-10">Tiền phải trả còn lại:</p>
                  <p className="col-md-2 text-right">
                    {<RenderAmount price={total() - deposit} />}
                  </p>
                </Row>
              </>
              : ''
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {statusOder === 'CANCELED' ? <div>
            <h5 className="text-danger text-end">Lý do huỷ đơn: {cancelReason || "Không có lý do"}</h5>
          </div> : null}
        </Col>
      </Row>
      <Row>
        <Col>
          {loading ? <div className="delivery-info_wrapButton">
            <h5>Đang thực hiện. Vui lòng chờ</h5>
          </div>
            :
            <div className="delivery-info_wrapButton">

              {status === "CREATE" ?
                <Button
                  className="btn btn-info waves-effect waves-light"
                  type="submit"
                >
                  Tạo
                </Button>
                : ""}
              {status === "UPDATE" ? <>
                <Button
                  className="btn btn-info waves-effect waves-light"
                  style={{ marginRight: 5 }}
                  type="submit"
                >
                  Lưu
                </Button>
                <Button
                  className="btn btn-warning waves-effect waves-light"
                  style={{ marginRight: 5 }}
                  type="button"
                  onClick={() => navigate(0)}
                >
                  Huỷ Lưu
                </Button>
              </> : ''}
              {status === 'INFO' ?
                <>

                  {renderExportExcelButton()}
                  {/* {renderSendMailButton()} */}
                  {renderPrintBillButton()}
                  {statusAllowUpdate?.CONFIRMED ? renderSetConfirmAndPrintBillButton() : null}
                  {/* {statusAllowUpdate?.PREPARING ? renderSetPreparingButton() : null} */}
                  {/* {statusAllowUpdate?.CHECK_PACKAGE ? renderSetCheckPackageButton() : null} */}
                  {/* {statusAllowUpdate?.IN_PACKAGE ? renderSetPackageInButton() : null} */}
                  {statusAllowUpdate?.WAIT_CUSTOMER_PICK_UP ? renderSetWaitPickupButton() : null}
                  {isShowShipMethod && statusAllowUpdate?.DELIVERING ? renderSetDeliveryProviderButton() : ''}
                  {statusAllowUpdate?.WAITING_PAY ? renderSetWaitingPayButton() : null}
                  {statusAllowUpdate?.WAITING_EXPORT_VAT ? renderSetWaitingExportVATButton() : null}
                  {statusAllowUpdate?.FINISHED ? renderSetFinishButton() : null}
                  {statusAllowUpdate?.CANCELED ? renderSetCancelButton() : null}
                  {statusAllowUpdate?.EDIT ? renderSetEditButton() : null}
                </>
                : ''}
            </div>
          }
        </Col>
      </Row>

    </div >
  );
});

export default DeliveryInfo;

DeliveryInfo.propTypes = {
  listProduct: PropTypes.array,
  costDelivery: PropTypes.number,
  status: PropTypes.string,
  statusOder: PropTypes.string,
  handleCancel: PropTypes.func,
  handleUpdateStatus: PropTypes.func,
  loading: PropTypes.bool,
  handleConfirmed: PropTypes.func,
  handlePreparing: PropTypes.func,
  handleDeliver: PropTypes.func,
  handleFinish: PropTypes.func,
  handlePrint: PropTypes.func,
  getListCostDelivery: PropTypes.func,
  printRef: PropTypes.any,
  exportRef: PropTypes.any,
  isShowShipMethod: PropTypes.bool,
  sendBillMail: PropTypes.func

};
