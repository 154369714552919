export const TIME_FIELD_INVENTORY = {
  PREPARING: 'packingTime',
  CHECK_PACKAGE: 'checkPackageTime',
  FINISH_TIME: 'finishTime',
  IN_PACKAGE: 'inPackage',
  WAITING_PAY: 'waitingPay',
  WAITING_EXPORT_VAT: 'waitingExportVAT',
  TO_SHIPPER: 'toShipper',
}

export const TIME_FIELD_TITLE_INVENTORY = {
  PREPARING: 'Soạn hàng',
  CHECK_PACKAGE: 'Kiểm hàng',
  FINISH_TIME: 'Hoàn thành',
  IN_PACKAGE: 'Đóng gói',
  WAITING_PAY: 'Chưa thanh toán',
  WAITING_EXPORT_VAT: 'Đợi xuất HĐ VAT',
  TO_SHIPPER: 'Đã giao hàng',
}