import React, { useEffect, useRef, useState } from "react";
import { get, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Row, Input, Card, CardBody, Button } from "reactstrap";
import toastr from 'toastr';
import Loading from "../../components/Loading/loading";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import './index.scss'
const ListTempBill = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [timeNow, setTimeNow] = useState();
  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0,
    total: null,
  });
  const [data, setData] = useState([]);
  const clearPagi = useRef();

  useEffect(() => {
    getData(pagination.offset);
  }, [timeNow]);

  useEffect(() => {
    setData([]);
    clearTimeout(clearPagi.current);
    clearPagi.current = setTimeout(() => {
      setPagination({
        ...pagination,
        offset: 0,
      })
      setTimeNow(moment().valueOf())
      setData([]);


    });
  }, [search]);

  const getData = (offset) => {
    setLoading(true);
    let params = {
      limit: pagination.limit,
      offset,
      ...(search ? { search: search } : {}),
    };

    get(`/bills/list-temp-bill`, { params }, true)
      .then(({ list, total }) => {
        setData([...data, ...(list || [])]);
        setPagination({
          ...pagination,
          total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exchangeBill = (id) => {
    setLoading(true);
    post('/bills/exchange-temp-bill-to-bill', {
      id
    }, {})
      .then(({ billId }) => {
        toastr.success('Tạo đơn thành công');
        navigate(`/oder-manager/${billId}`)
      })
      .catch((err) => {
        toastr.error('Không thể tạo đơn')
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <div className="list-oder-page">
    <Breadcrumbs title="Đơn hàng" breadcrumbItem="danh sách đơn hàng" />
    <div className="list-oder-page_table">
      <Row>
        <Col sm={12}>
          <Input width={'300px'} value={search} onChange={(e) => {
            setSearch(e.target.value)
          }} placeholder="Tìm kiếm đơn" />
        </Col>
      </Row>
      <div className={`list-item_list ${isMobile ? 'mobile' : ''}`} id="scroll-infinite">
        <InfiniteScroll
          scrollableTarget="scroll-infinite"
          dataLength={data.length}
          next={() => {
            setPagination({
              ...pagination,
              offset: pagination.offset + pagination.limit
            });
            setTimeNow(moment().valueOf())
          }}
          hasMore={pagination.offset + pagination.limit <= pagination.total}
          loader={<h4>Đang lấy dữ liệu...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Đã hết sản phẩm</b>
            </p>
          }
          className={`temp-list-bill-card ${isMobile ? 'mobile' : ''}`}
        >
          {
            data.map(bill =>
              <Card className="temp-bill-card">
                <CardBody>
                  <h5>Tên: {bill.name}</h5>
                  <h6>SDT: {bill.phone} - Email: {bill.email}</h6>
                  <h6>Địa chỉ: {bill?.Ward?.name}, {bill?.District?.name}</h6>
                  {/* <div>
                    Ghi chú: {bill.note}
                  </div>
                  <div>
                    thông tin hoá đơn: {bill.noteInvoice}
                  </div>
                  <div>Sản phẩm: {bill?.TempBillDetails?.map(e => `${e.quantity} ${e?.Product?.name}`).join(',')}</div> */}
                  <div>
                    <Button className="btn-info" onClick={() => exchangeBill(bill.id)}>Chuyển thành đơn thật</Button>
                  </div>
                </CardBody>

              </Card>

            )
          }
        </InfiniteScroll>

        {data?.length === 0 ? <h5 className="text-center">Không có đơn tạm</h5> : ''}
      </div>

    </div>
  </div >
}

export default ListTempBill;