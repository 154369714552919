import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button } from "reactstrap";
import "./list-table.scss";

const ListTable = (props) => {
  const [phone, setPhone] = useState("");

  const {
    rowData,
    handleSearchName,
    handleSearchPhone,
    handleChangeCode,
    handleClickBlock,
    handleChangeRole,
  } = props;

  const handleSearch = (value) => {
    if(!value.match(/\D+/)){
      setPhone(value);
      handleSearchPhone(value);
    }
  };
  return (
    <div className="list-account-table">
      <div className="list-account-table_content">
        <table>
          <thead>
            <tr>
              <th>
                <input
                  className="list-account-table_input"
                  onChange={(e) => handleSearchName(e.target.value)}
                  placeholder="Tên"
                />
              </th>
              <th>
                <input
                  value={phone}
                  className="list-account-table_input"
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Số điện thoại"
                />
              </th>
              <th>Địa chỉ</th>
              <th>Code FS</th>
              <th>Vai trò</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rowData &&
              rowData.length > 0 &&
              rowData.map((item, idx) => (
                <tr key={`list-account-table${idx.toString()}`}>
                  <td>{item.name}</td>
                  <td>{item.phone}</td>
                  <td>
                    {item.street && item.province && item.district && item.ward
                      ? `${item.street}, ${item.ward}, ${item.district}, ${item.province}`
                      : "-"}
                  </td>
                  <td>
                    <div className="list-account-table_wrapCode">
                      {item.code}
                      <Button
                        color="dark"
                        type="button"
                        onClick={() => handleChangeCode(item.code, item.id)}
                        className="list-account-table_buttonCode btn btn-dark waves-effect waves-light"
                      >
                        {item.code ? "Hủy" : "Tạo"}
                      </Button>
                    </div>
                  </td>
                  <td>{item.role}</td>
                  <td>
                    <Button
                      style={{ marginRight: 5 }}
                      color="dark"
                      className="btn btn-dark waves-effect waves-light"
                      type="button"
                      onClick={() => handleChangeRole(item.id, item.role)}
                    >
                      Đổi vai trò
                    </Button>
                    <Button
                      className={`btn ${
                        item.status === "ACTIVE" ? "btn-success" : "btn-danger"
                      } waves-effect waves-light`}
                      type="button"
                      onClick={() => handleClickBlock(item.id, item.status)}
                    >
                      {item.status === "ACTIVE" ? "Khóa TK" : "Mở khóa"}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListTable;

ListTable.propTypes = {
  rowData: PropTypes.array,
  handleSearchName: PropTypes.func,
  handleSearchPhone: PropTypes.func,
  handleClickBlock: PropTypes.func,
  handleChangeCode: PropTypes.func,
  handleChangeRole: PropTypes.func,
};
