import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { TIME_FIELD_INVENTORY, TIME_FIELD_TITLE_INVENTORY } from '../../constants';

import {
  Modal,
  ModalHeader,
  Input,
  Button,
  CardImg,
  CardTitle,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Badge,
  ModalBody,
} from "reactstrap";
import './get-scan-inventory.scss';

const typeToText = {
  packingTime: "Soạn hàng",
  inPackage: "Đóng gói",
  toShipper: "Giao hàng",
  checkPackageTime: "Kiểm hàng",
  finishTime: "Hoàn thành",
  waitingPay: "Chờ trả tiền",
}

const ScanInventory = () => {
  const clearSortTyping = useRef();
  const inputRef = useRef();
  const [billId, setBillId] = useState('');
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [countScanInventory, setCountScanInventory] = useState({});

  useEffect(() => {
    inputRef?.current?.focus();
    getCountScanInventory();
    setInterval(() => {
      getCountScanInventory();

    }, 5000)
  }, [])
  const onChangeBillId = (id) => {
    setBillId(id);
  }

  const getCountScanInventory = () => {
    get(`/bills/count-scan-inventory`, {}, {})
      .then((result) => {
        console.log(result);
        setCountScanInventory(result)
      })
  }
  const save = (id) => {
    if (id === '') {
      return;
    }
    clearTimeout(clearSortTyping.current);
    setLoading(true)
    get(`/bills/get-scan-inventory/${id}`, {}, {})
      .then((result) => {
        const currenthSearch = [];
        for (const res of result) {
          const temp = {
            billId: res.billId,
            status: 1,
            message: []
          }

          if (res.packingTime) {
            temp.message.push({ msg: `${typeToText.packingTime}:${moment(res.packingTime).format('DD-MM-YYYY HH:mm:ss')}`, color: 'success', scanAt: moment(res.packingTime) })
          }
          if (res.inPackage) {
            temp.message.push({ msg: `${typeToText.inPackage}:${moment(res.inPackage).format('DD-MM-YYYY HH:mm:ss')}`, color: 'success', scanAt: moment(res.inPackage) })
          }

          if (res.toShipper) {
            temp.message.push({ msg: `${typeToText.toShipper}:${moment(res.toShipper).format('DD-MM-YYYY HH:mm:ss')}`, color: 'success', scanAt: moment(res.toShipper) })
          }

          if (res.checkPackageTime) {
            temp.message.push({ msg: `${typeToText.checkPackageTime}:${moment(res.checkPackageTime).format('DD-MM-YYYY HH:mm:ss')}`, color: 'success', scanAt: moment(res.checkPackageTime) })
          }
          if (res.finishTime) {
            temp.message.push({ msg: `${typeToText.finishTime}:${moment(res.finishTime).format('DD-MM-YYYY HH:mm:ss')}`, color: 'success', scanAt: moment(res.finishTime) })
          }

          if (res.waitingPay) {
            temp.message.push({ msg: `${typeToText.waitingPay}:${moment(res.waitingPay).format('DD-MM-YYYY HH:mm:ss')}`, color: 'success', scanAt: moment(res.waitingPay) })
          }
          temp.message = temp.message.sort((a, b) => a.scanAt.isAfter(b.scanAt) ? 1 : -1)
          const scanDuplicate = [];
          res.BillScanInventoryHistories.forEach(e => {
            scanDuplicate.push({ msg: `Trùng ${typeToText[e.type]}:${moment(e.createdAt).format('DD-MM-YYYY HH:mm:ss')}`, color: 'warning', scanAt: moment(e.createdAt) });
          })
          temp.message = [...temp.message, ...scanDuplicate.sort((a, b) => a.scanAt.isAfter(b.scanAt) ? 1 : -1)]
          currenthSearch.unshift(temp);
        }
        if (currenthSearch.length === 0) {
          currenthSearch.push({
            billId: '',
            status: 0,
            message: [{ msg: 'Không tìm thấy đơn hàng', color: 'danger' }]
          })
        }
        setHistory(currenthSearch)
      })
      .catch(e => {
        setHistory([{
          billId: id,
          status: 0,
          message: [{ msg: e.error, color: 'danger' }]
        }])
      })
      .finally(() => {
        setBillId('');
        setLoading(false);
        inputRef?.current?.focus();
      })
  }


  return <div className="page-content page-product">
    <Breadcrumbs title="Đơn hàng" breadcrumbItem="Quét đơn" />
    <Row className="scan-inventory">
      <Col md={12} xs={12}>
        <div>
          <Row className="get-scan-inventory d-flex justify-content-center">
            <Col md={3} xs={12}>
              <ul>
                <li>
                  <b>{TIME_FIELD_TITLE_INVENTORY['PREPARING']}</b>: {countScanInventory[TIME_FIELD_INVENTORY['PREPARING']]} đơn
                </li>
                <li>
                  <b>{TIME_FIELD_TITLE_INVENTORY['CHECK_PACKAGE']}</b>: {countScanInventory[TIME_FIELD_INVENTORY['CHECK_PACKAGE']]} đơn
                </li>
                <li>
                  <b>{TIME_FIELD_TITLE_INVENTORY['IN_PACKAGE']}</b>: {countScanInventory[TIME_FIELD_INVENTORY['IN_PACKAGE']]} đơn
                </li>
                <li>
                  <b>Tổng</b>: {countScanInventory['total']} đơn
                </li>
              </ul>
            </Col>
            <Col md={6} xs={12}>
              <h4>Quét tìm đơn hàng</h4>
              <form className="get-scan-inventory_input" onSubmit={(e) => {
                e.preventDefault();
                save(billId)
              }}>
                <input
                  ref={inputRef}
                  disabled={loading}
                  value={billId}
                  onChange={(e) => {
                    onChangeBillId(e.target.value)
                  }}
                  onKeyup={(event) => {
                    const code = event.code;
                    const key = event.keyCode || event.which
                    if (code === "Enter" || code === "NumpadEnter" || key === '13') {
                      event.preventDefault();
                      save(billId);
                    }
                  }}
                  onBlur={() => {
                    if (!isMobile) {
                      inputRef?.current?.focus();
                    }
                  }}
                  placeholder="Nhập mã đơn hàng"
                />
                <span className="get-scan-inventory_input_icon" onClick={() =>  save(billId)}><i className="fa fa-search"></i></span>
              </form>
            </Col>
            <Col md={3} xs={0}></Col>
          </Row>
          <hr />
          <h4>Kết quả</h4>

          <div className="get-scan-inventory_history">
            <Row >
              {history.map((e, i) =>
                <Col key={i} md={6}>
                  <Card>
                    <CardBody>
                      <h5>{e.billId}</h5>
                      <span>Tình trạng: <strong className={e.status ? "text-success" : 'text-danger'}>
                        <ul>
                          {e.message.filter(e => e.color === 'success').map((e, i) => <li className={`text-${e.color}`} key={i}>{e.msg}</li>)}
                        </ul>
                        <hr />
                        <ul>
                          {e.message.filter(e => e.color !== 'success').map((e, i) => <li className={`text-${e.color}`} key={i}>{e.msg}</li>)}
                        </ul>
                      </strong></span>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>


        </div>
      </Col>
    </Row>
  </div>
}

export default ScanInventory