import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import { get, post } from "../../helpers/api_helper";
import ModalConfirm from "../../components/Modal/modalConfirm";
import Loading from "../../components/Loading/loading";
import toastr, { } from "toastr";

const ListWaitingExportVATInventory = () => {
  const [waitingExportVATList, setWaitingExportVATList] = useState([]);
  const [itemChangeStatus, setItemChangeStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const onConfirmChangeStatusItem = () => {
    let action = null;
    if (itemChangeStatus.type === 'BILL') {
      action = post("/bills/status/finish", { ids: [itemChangeStatus.billId] })
    }
    if (itemChangeStatus.type === 'BILL_SCAN') {
      action = post(`/bills/scan-inventory/${itemChangeStatus.billId}`, {
        type: "FINISH_TIME"
      }, {})
    }
    if (action) {
      setLoading(true);
      action.then((res) => {
        if (res.result) {
          setWaitingExportVATList(waitingExportVATList.filter(e => e.billId !== itemChangeStatus.billId));
          setItemChangeStatus(null);
        }
      })
        .catch((err) => {
          toastr.error(err.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    get(`/bills/get-list-waiting-export-vat`, {}, {})
      .then(data => {
        setWaitingExportVATList(data)
      })
      .catch(() => {
        toastr.error('Đã có lỗi xảy ra. Vui lòng tải lại trang hoặc liên hệ admin')
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])
  return <div className="page-content page-product">
    <Breadcrumbs title="Đơn hàng" breadcrumbItem="DS chờ xuất hoá đơn" />
    {loading ? (
      <Loading
        height={50}
        width={50}
        className="loading-custom-page"
        type="spinningBubbles"
      />
    ) : null}
    {waitingExportVATList.length === 0 && !loading.page ? <h5 className="text-success text-center w-100">Không có HĐ cần xuất VAT</h5> : ''}
    <Row className="scan-inventory">
      {waitingExportVATList.map(({ billId, name, phone, Ward, District, type, }) =>
        <Col md={3} xs={12}>
          <Card className="point-click" onClick={() => setItemChangeStatus({
            billId, type
          })}>
            <CardBody>
              <h5>{billId}</h5>
              {
                (name || phone) ? <div className="oder-info_infoCustomer"><b className="text-info">{`${name} - ${phone}`}</b></div> : null
              }
              {
                (Ward && District) ? <div className="oder-info_address">Địa chỉ: {Ward?.name} - {District?.name}</div> : null
              }
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
    <ModalConfirm
      isOpen={!!itemChangeStatus}
      title={"Xác nhận"}
      desc={"Bạn đã xuất thành công VAT, muốn chuyển đơn về trạng thái hoàn thành?"}
      handleCancel={() => setItemChangeStatus(null)}
      handleAccept={onConfirmChangeStatusItem}
    />
  </div>
}

export default ListWaitingExportVATInventory