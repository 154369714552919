import { useEffect, useState, useRef } from 'react';
import { get, post, del, put } from '../../helpers/api_helper';
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalBody, ModalHeader, ModalFooter, Table, Input } from "reactstrap"
import Select from "react-select";
import toastr from 'toastr';
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import Switch from "react-switch";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import './index.scss';
import ProductRepresentative from './productRepresentative';

const Category = props => {
  const clearSortTyping = useRef();
  const [list, setList] = useState([]);
  const [selectedParentCreate, setSelectedParentCreate] = useState();
  const [nameCreate, setNameCreate] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectProductOfCate, setSelectProductOfCate] = useState();

  const listParentIds =  list.filter((cate) => cate.parentId).map(cate => cate.parentId);
  const listCateHaveParentIds =  list.filter((cate) => cate.parentId).map(cate => cate.id);
  const listCateCanParent = list.filter((cate) => !listCateHaveParentIds.includes(cate.id)).map(cate => ({
    value: cate.id,
    label: cate.name
  }));
  const sortCate = (data) => {
  const listParentIds =  data.filter((cate) => cate.parentId).map(cate => cate.parentId);

    const parentCates = data.filter((cate) => listParentIds.includes(cate.id));
    const list = [];
    parentCates.forEach(cate => {
      list.push(cate);
      data.forEach((childCate) => {
        if (childCate.parentId === cate.id) {
          list.push(childCate);
        }
      })
    });
    data.forEach((childCate) => {
      if (!childCate.parentId && !listParentIds.includes(childCate.id)) {
        list.push(childCate);
      }
    })
    setList(list)
  }

  useEffect(() =>{
    get(`/categories/list`)
      .then((data) => {
        sortCate(data)
      })
  }, [])

  const onCreate = () => {
    setIsCreating(true);
    post('/categories/create', {
      name: nameCreate,
      parentId: selectedParentCreate?.value || undefined
    })
      .then(() => {
        toastr.success('Lưu danh mục thành công')
        return get(`/categories/list`)
      })
      .then((data) => {
        sortCate(data)
        setNameCreate('')
        setSelectedParentCreate()
      })
      .catch((err) => {
        toastr.error(err.error)
      })
      .finally(() => {
        setIsCreating(false)
      })
  }
  const deleteCategory = () => {
    setIsDeleting(true);
    del(`/categories/delete/${selectedDelete.id}`)
      .then(() => {
        toastr.success('Xoá danh mục thành công')
        return get(`/categories/list`)
      })
      .then((data) => {
        sortCate(data)
        setSelectedDelete(null);
      })
      .catch((err) => {
        toastr.error(err.error)
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }
  const onUpdate = (item, key, value) => {
    const temp = {...item}
    const { id, name, parentId, sort, productRepresentative } = temp;
    setIsUpdating(true);
    const payload = {
      name,
      parentId,
      sort,
      productRepresentative,
    }
    payload[key] = value;
    if (key === 'parentId' && !!value) {
      payload.productRepresentative = null
    }
    put(`/categories/update/${id}`, payload)
      .then(() => {
        toastr.success('Cập nhật danh mục thành công')
        return get(`/categories/list`)
      })
      .then((data) => {
        sortCate(data)
      })
      .catch((err) => {
        toastr.error(err.error)
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }

  return (
    <>
      <div className="page-content page-category">
          <Breadcrumbs title="Danh mục" breadcrumbItem="Danh sách danh mục" />
          <Row>
            <Col md={8} xs={12}>
              <Card className="list-category">
                <CardBody>
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th width="30%">
                            Tên
                          </th>
                          <th width="20%">
                            Danh mục cha
                          </th>
                          <th width="15%">
                            Sắp xếp
                          </th>
                          <th width="15%">
                            SP tiêu biểu
                          </th>
                          <th width="20%">
                            Xoá
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map(cate => 
                          <tr key={cate.id}>
                            <td><Input defaultValue={cate.name} onBlur={(e) => onUpdate(cate, 'name', e.target.value)}/></td>
                            <td>
                              {
                                listParentIds.includes(cate.id) ? <h5 className="text-success">Danh mục cha</h5> :
                                <Select
                                  value={cate.parentId 
                                    ? {
                                      value: cate.ParentCategory.id,
                                      label: cate.ParentCategory.name,
                                      } 
                                    : null
                                  }
                                  onChange={(option) => onUpdate(cate, 'parentId', option?.value || null)}
                                  placeholder="Chọn danh mục cha"
                                  options={listCateCanParent.filter((c) => c.id !== cate.id)}
                                  isClearable
                                  classNamePrefix="select2-selection"
                               />
                              }
                            </td>
                            <td className="">
                              <Input defaultValue={ cate.sort || '' } type="number" min="0" onBlur={(e) =>  onUpdate(cate, 'sort', Number(e.target.value) || null) } />
                            </td>
                            <td>
                              {listParentIds.includes(cate.id) || !cate.parentId
                                ? <div>{cate.productRepresentative  ? (cate.productRepresentative || '').split(',').length : 0 } SP  <Button className="btn-infp" onClick={() => setSelectProductOfCate(cate)}>Chọn SP</Button></div>
                                : null}
                              </td>
                            <td className="">
                              <Button className="btn-danger" onClick={() => setSelectedDelete(cate)}><i className="fa fa-trash"></i></Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} xs={12}>
              <Card>
                
                <CardBody>
                  <Row className="mb-3">
                    <label
                      className="col-md-4 col-form-label"
                    >
                      Name
                      </label>
                    <div className="col-md-8">
                      <input
                        value={nameCreate}
                        onChange={(e) => setNameCreate(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      className="col-md-4 col-form-label"
                    >
                      Danh mục cha
                      </label>
                    <div className="col-md-8">
                    <Select
                      value={selectedParentCreate}
                      onChange={(option) => setSelectedParentCreate(option)}
                      placeholder="Chọn danh mục cha"
                      options={listCateCanParent}
                      isClearable
                      classNamePrefix="select2-selection"
                    />
                    </div>
                  </Row>
                  <Row className="mb-0">
                    <CardTitle align="right"><Button disabled={isCreating || isUpdating || !nameCreate} onClick={onCreate} className="btn btn-success">Tạo danh mục</Button></CardTitle>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={selectedDelete !== null}
            toggle={() => setSelectedDelete(null)}
            centered={true}
          >
            {selectedDelete !== null ? 
            <>
              <ModalHeader>
                Xoá danh mục "{selectedDelete?.name}"
              </ModalHeader>
              <ModalBody align="center" >
                  <h5 >Bạn có chắc sẽ xoá danh mục này không?</h5>
              </ModalBody>
              <ModalFooter>
                <Button disabled={isDeleting} className="btn-danger mr-3" onClick={deleteCategory}>Có</Button>
                <Button disabled={isDeleting} className="btn-secondary mr-3" onClick={() => setSelectedDelete(null)}>Huỷ</Button>

              </ModalFooter>
            </>
            : null}
            
          </Modal>
          { selectProductOfCate
            ? <ProductRepresentative
              category={selectProductOfCate}
              onClose={() => setSelectProductOfCate()} 
              onSave={(cate, productRepresentative) =>  {
                setSelectProductOfCate()
                onUpdate(cate, 'productRepresentative', productRepresentative)
              }}
            />
            : null}
      </div>
    </>
  )
}

export default Category
