import { useNavigate, useLocation } from 'react-router';

const withRouter = WrappedComponent => props => {
  const navigate = useNavigate();
  const location = useLocation();
  // other hooks

  return (
    <WrappedComponent
      {...props}
      {...{ navigate, location }}
    />
  );
};

export default withRouter;