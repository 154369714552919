import PropTypes from 'prop-types'
import React from "react"
import { Outlet } from 'react-router'

const NonAuthLayout = () => {
  return <React.Fragment><Outlet /></React.Fragment>
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default NonAuthLayout
