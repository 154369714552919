import React, { useEffect, useState } from "react";
import ListTable from "./list-table";
import Pagination from "./pagination";
import { get, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toastr from "toastr";
import "./index.scss";
import ModalConfirm from "../../components/Modal/modalConfirm";

let timeOutSearch;
const LIMIT_PAGE = 5;

const ListAccount = () => {
  const [list, setList] = useState([]);
  const [pageActive, setPageActive] = useState(1);
  const [total, setTotal] = useState(0);
  const [roleActive, setRoleActive] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchPhone, setSearchPhone] = useState('');

  const [idChangeRole, setIdChangeRole] = useState(null);
  const [changeCode, setChangeCode] = useState({
    id: null,
    code: null,
  });
  const [changeBlock, setChangeBlock] = useState({
    id: null,
    status: null,
  });

  useEffect(() => {
    if (searchName || searchPhone) {
      const filters = [];
      if (searchName) {
        filters.push({
          field: 'name',
          operator: 'PARTIAL',
          value: searchName
        })
      }

      if (searchPhone) {
        filters.push({
          field: 'phone',
          operator: 'PARTIAL',
          value: searchPhone
        })
      }
      get(
        `/accounts/list?params={"page": ${pageActive},"limit": ${LIMIT_PAGE},"filters": ${JSON.stringify(filters)}}`
      ).then((res) => {
        setTotal(res.count);
        setList(res.rows);
      });
    } else {
      get(
        `/accounts/list?params={"page": ${pageActive},"limit": ${LIMIT_PAGE}}`
      ).then((data) => {
        setTotal(data.count);
        setList(data.rows);
      });
    }
  }, [searchName, searchPhone]);

  const handleChangeCode = () => {
    post("/accounts/update-code", {
      userId: changeCode.id,
      isClean: !!changeCode.code,
    })
      .then((res) => {
        let clone = [...list];
        setList(
          clone.map((item) => {
            if (item.id === changeCode.id) {
              return { ...item, codeDiscount: res.codeDiscount };
            }
            return item;
          })
        );
        setChangeCode({id: null, code: null})
        toastr.success("Cập nhật thành công");
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };

  const handleChangeRole = () => {
    post("/accounts/update-role", {
      userId: idChangeRole,
    })
      .then((res) => {
        let clone = [...list];
        setList(
          clone.map((item) => {
            if (item.id === idChangeRole) {
              return {
                ...item,
                role: res.role,
              };
            }
            return item;
          })
        );
        setIdChangeRole(null);
        toastr.success("Cập nhật thành công");
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };

  const handleClickBlock = () => {
    post("/accounts/update-status", {
      userId: changeBlock.id,
    })
      .then((res) => {
        let clone = [...list];
        setList(
          clone.map((item) => {
            if (item.id === changeBlock.id) {
              return {
                ...item,
                status: res.status,
              };
            }
            return item;
          })
        );
        setChangeBlock({id: null, status: null})
        toastr.success("Cập nhật thành công");
      })
      .catch((err) => {
        toastr.error(err.error);
      });
  };

  const handleChangePage = (page) => {
    if (page > 0) {
      setPageActive(page);
      get(
        `/accounts/list?params={"page": ${page},"limit": ${LIMIT_PAGE}}`
      ).then((data) => {
        setList(data.rows);
      });
    }
  };

  const handleSearchByName = (key) => {
    clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(() => {
      setPageActive(1)
      setSearchName(key);
    }, 300);
  };

  const handleSearchByPhone = (key) => {
    clearTimeout(timeOutSearch);
    timeOutSearch = setTimeout(() => {
      setPageActive(1)
      setSearchPhone(key);
    }, 300);
  };

  const formatDataTable = (data) => {
    return data.map((item) => ({
      id: item.id,
      status: item.status,
      name: item.name,
      phone: item.phone,
      street: item.address.street,
      province: item.provinceData?.name,
      district: item.districtData?.name,
      ward: item.wardData?.name,
      quantity: item.billCount,
      code: item.codeDiscount,
      role: item.role,
    }));
  };

  return (
    <div className="list-account-page">
      <Breadcrumbs title="Tài khoản" breadcrumbItem="danh sách tài khoản" />
      <div className="list-account-page_table">
        <ListTable
          rowData={formatDataTable(list)}
          handleSearchName={handleSearchByName}
          handleSearchPhone={handleSearchByPhone}
          handleClickBlock={(id, status) => {
            setChangeBlock({
              id,
              status
            })
          }}
          handleChangeCode={(code, id) => {
            setChangeCode({
              id,
              code,
            })
          }}
          handleChangeRole={(id, role) => {
            setIdChangeRole(id);
            setRoleActive(role);
          }}
        />
      </div>
      <div className="list-account-page_pagination">
        <Pagination
          total={total}
          perPage={LIMIT_PAGE}
          pageActive={pageActive}
          handleChangePage={handleChangePage}
        />
      </div>
      <ModalConfirm 
      isOpen={idChangeRole !== null} 
      title="Thay đổi vai trò"
      desc={`Bạn có chắc muốn thay đổi vai trò của tài khoản này thành ${roleActive === 'STAFF' ? 'CUSTOMER' : 'STAFF'} không?`}
      handleAccept={handleChangeRole}
      handleCancel={() => setIdChangeRole(null)} />
            <ModalConfirm 
      isOpen={changeCode.id !== null}
      title="Thay đổi CodeFS"
      desc={`${changeCode.code ? 'Bạn có chắc chắn muốn xóa CodeFS' : 'Bạn có chắc chắn muốn tạo CodeFS'} không?`}
      handleAccept={handleChangeCode}
      handleCancel={() => setChangeCode({id: null, code: null})} />
                  <ModalConfirm 
      isOpen={changeBlock.id !== null}
      title="Thay đổi CodeFS"
      desc={`${changeBlock.status === 'ACTIVE' ? 'Bạn có chắc chắn muốn khóa tài khoản này' : 'Bạn có chắc chắn muốn mở khóa tài khoản này'} không?`}
      handleAccept={handleClickBlock}
      handleCancel={() => setChangeBlock({id: null, status: null})} />
    </div>
  );
};

export default ListAccount;
