import { useEffect, useState } from 'react';
import { get, post } from '../../helpers/api_helper';
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, CardFooter } from "reactstrap"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DropzoneCustomize from '../../components/Dropzone';
import picUpload from '../../assets/images/assets/upload.png';
import ImageGallery from 'react-image-gallery';
import toastr from 'toastr';
import './index.scss';
import { uuid } from '../../utils';
const Carousel = props => {
  const [list, setList] = useState([]);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() =>{
    get(`/carousels/list`)
      .then((data) => {
        setList(data)
      })
  }, [])
  const save = () => {
    setIsSaving(true)
    post('/carousels/create', {
      list: list.map(picture => ({
        picture: picture.picture
      }))
    })
      .then(() => {
        toastr.success('Lưu carousel thành công')
      })
      .catch((err) => {
        toastr.error(err.error)
      })
      .finally(() => {
        setIsSaving(false)

      })
  }
  const removePic = (id) => {
    setList(list.filter(picture => picture.id !== id))
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    setList(items)
  }

  const onUploadSuccess = (url) => {
    const newList = [...list]
    const newPicture = {
      picture: url,
      id: uuid()
    }
    newList.push(newPicture)
    setList(newList)
  }

  return (
    <>
      <div className="page-content page-carousel">
       
          <Breadcrumbs title="Carousel" breadcrumbItem="Danh sách Carousel" />
          <Row>
            <Col md={{ size: 6, offset: 3 }} xs={12}>
              <Card className="list-carousel">
                <CardBody>
                  <div>
                    <ImageGallery
                    
                      items={list.map(picture => ({
                        renderItem: () => <img className="border" src={picture.picture} width="100%" height="100%"/>
                      }))}
                      showPlayButton={false}
                      showFullscreenButton={false}
                    />
                  </div>

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="list-pictures"
                          {...provided.droppableProps}
                        >
                          {list.map((picture, index) =>
                              <Draggable key={`${picture.id}`} draggableId={picture.picture} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Card
                                    className="card-picture"
                                  >
                                    <img className="border" src={picture.picture} width="100%" height="100%"/>
                                    <div className="position-absolute">
                                      <Button onClick={() => removePic(picture.id)} className="btn-link btn-danger" style={{ background: 'none', border: 'none'}}><i className="text-danger fa fa-trash"></i></Button>
                                    </div>       
                                  </Card>
                                </div>
                              )}
                            </Draggable>
                          )}
                          {provided.placeholder}
                          <Card className="card-picture" onClick={() => setIsOpenUpload(true)}><img src={picUpload}/></Card>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </CardBody>
                <CardFooter align="right">
                  <Button disabled={isSaving} className="btn-success" onClick={save}>Lưu</Button>
                </CardFooter>
              </Card>
              <Modal
                isOpen={isOpenUpload}
                toggle={() => setIsOpenUpload(!isOpenUpload)}
                centered={true}
              >
                {isOpenUpload ? 
                <>
                  <ModalBody style={{ minHeight: 150}} >
                    <DropzoneCustomize 
                      onUploadSuccess={onUploadSuccess}
                      urlUpload={() => '/carousels/upload-picture'}
                    />
                  </ModalBody>
                </>
                : null}
                
              </Modal>
            </Col>
          </Row>
      </div>
    </>
  )
}

export default Carousel
