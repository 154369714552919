import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {

  CardImg,
  CardTitle,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  Input,

} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InfiniteScroll from "react-infinite-scroll-component";
import { get } from "../../helpers/api_helper";

import "react-virtualized/styles.css";
import "./index.scss";

let clearSearch;

const Products = (props) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    limit: 30,
    offset: 0,
    total: null,
  });
  const [listProducts, setListProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [filterSoldOut, setFilterSoldOut] = useState(false);

  const formatListProduct = (data, addMore = false) => {
    const { list, total, limit, offset } = data;
    setPagination({
      total,
      limit,
      offset,
    });
    const products = list.map((product) => {
      const { name, picture, id } = product;
      const img = picture.find((p) => p.type === "image");
      return {
        name,
        id,
        picture: img?.url,
      };
    });
    return addMore ? [...listProducts, ...products] : products;
  };

  const onChangeSearch = (e) => {
    const search = e.target.value;
    setSearch(search);
    clearTimeout(clearSearch);
    clearSearch = setTimeout(() => {
      get('/products/list', {
        params: {
          limit: pagination.limit,
          offset: 0,
          ...(search ? { search } : {}),
          ...(filterSoldOut ? { isSoldOut: filterSoldOut } : {}),
        }
      })
        .then((data) => formatListProduct(data))
        .then((list) => {
          setListProducts(list);
        })

    }, 300);
  };

  const onChangeFilter = () => {
    const newFilter = !filterSoldOut;
    setFilterSoldOut(newFilter);
    get('/products/list', {
      params: {
        limit: pagination.limit,
        offset: 0,
        ...(search ? { search } : {}),
        ...(newFilter ? { isSoldOut: newFilter } : {}),
      }
    })
      .then((data) => formatListProduct(data))
      .then((list) => {
        setListProducts(list);
      })
  };


  const loadMoreProducts = () => {
    get('/products/list', {
      params: {
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
        ...(search ? { search } : {}),
        ...(filterSoldOut ? { isSoldOut: filterSoldOut } : {}),
      }
    })
      .then((data) => formatListProduct(data, true))
      .then((list) => {
        setListProducts(list);
      })
  };

  useEffect(() => {
    get(`/products/list?offset=${pagination.offset}&limit=${pagination.limit}`)
      .then((data) => formatListProduct(data))
      .then((list) => {
        setListProducts(list);
      })
  }, []);

  return (
    <>
      <div className="page-content page-product">
        <Breadcrumbs title="Sản phẩm" breadcrumbItem="danh sách sản phẩm" />
        <Row className="data-content">
          <Col className="left-list-main">
            <div className="search">
              <input
                type="text"
                className="form-control"
                placeholder="Tìm kiếm"
                value={search}
                onChange={onChangeSearch}
              />
            </div>
            <div className="info-product">
              <div>
                {listProducts.length}/{pagination.total} sp
              </div>
              <div>
                <Label className="mb-0">
                  <Input type="checkbox" value={filterSoldOut} onChange={onChangeFilter} />
                  <span> Lọc hết hàng</span>
                </Label>
              </div>
              <div className="float-right">
                <Button
                  className="btn-info"
                  onClick={() => {
                    navigate(`/product/create`)
                  }}
                >
                  Tạo sản phẩm
                </Button>
              </div>
            </div>
            <div className="left-list-products" id="scroll-infinite">
              <InfiniteScroll
                scrollableTarget="scroll-infinite"
                dataLength={listProducts.length}
                next={loadMoreProducts}
                hasMore={listProducts.length <= pagination.total}
                loader={<h4>Đang lấy dữ liệu...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Đã hết sản phẩm</b>
                  </p>
                }
              >
                {listProducts.map((product) => (
                  <Card
                    key={`left-${product.id}`}
                    outline
                    className="border mb-1 point-click"
                    onClick={() => {
                      navigate(`/product/detail/${product.id}`)
                    }}
                  >
                    <Row className="g-0 align-items-center">
                      <Col xs={3}>
                        <CardImg className="img-fluid" src={product.picture} />
                      </Col>
                      <Col xs={9}>
                        <CardBody className="p-1">
                          <CardTitle className="h5 product-name">
                            {product.name}
                          </CardTitle>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Products;
