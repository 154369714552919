import PropTypes from "prop-types";
import React, { useRef } from "react";
import OderInfo from "./oder-info";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import "./list.scss";

const ListItemOder = (props) => {
  const {
    listData = [],
    handleShowMore = () => { },
    total = 10,
    title,
    status,
  } = props;

  const navigate = useNavigate();
  const contentRef = useRef()
  const sortData = (data) => {
    let clone = [...data];
    clone.sort((a, b) => {
      if (a["updatedAt"] > b["updatedAt"]) {
        return -1;
      }
      if (a["updatedAt"] < b["updatedAt"]) {
        return 1;
      }
      return 0;
    });
    return clone;
  };

  const handleShowListOder = (status) => {
    navigate({
      pathname: '/list-detail-oder',
      state: {
        status
      }
    });
  };

  const handleDetail = (item) => {
    navigate({
      pathname: `/oder-manager/${item.id}`,
      state: {
        status: 'INFO',
        idBill: item.id,
      }
    });
  };

  return (
    <div className="list-item">
      <div className="list-item_title" onClick={() => {
        contentRef.current.focus();
      }}>
        <h5>{title}</h5>
      </div>
      <div
        className="list-item_list"
        id={`scroll-infinite-${status}`}
        ref={contentRef}
        tabIndex="0">
        {listData.length > 0 ? (
          <InfiniteScroll

            scrollableTarget={`scroll-infinite-${status}`}
            dataLength={listData.length}
            next={() => handleShowMore(status)}
            hasMore={listData.length < total}
            loader={<h4>Đang lấy dữ liệu...</h4>}
            endMessage={
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                <b>Đã hết sản phẩm</b>
              </p>
            }
          >
            {sortData(listData).map((item, idx) => (
              <div
                key={`list-item-${idx.toString()}`}
                className="list-item_item"
              >
                <OderInfo
                  id={item.id}
                  district={item.District}
                  ward={item.Ward}
                  name={item.name}
                  phone={item.phone}
                  price={item.total}
                  createdAt={item.createdAt}
                  cancelReason={item.cancelReason}
                  status={item.status}
                  handleDetail={() => handleDetail(item)}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <h6 className="text-center"> Chưa có đơn hàng </h6>
        )}
      </div>
      <div className="list-item_totalOder">
        <h5 >{`${total} đơn`}</h5>
      </div>
    </div>
  );
};

export default ListItemOder;

ListItemOder.propTypes = {
  listData: PropTypes.array,
  title: PropTypes.string,
  handleShowMore: PropTypes.func,
  totalList: PropTypes.number,
  status: PropTypes.string,
  handleConfirmed: PropTypes.func,
  handlePreparing: PropTypes.func,
  handleDelivery: PropTypes.func,
  handleFinish: PropTypes.func,
  handleCancel: PropTypes.func,
};
