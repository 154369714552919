import ReactPlayer from 'react-player';
import { Button, Card, Col, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import picUpload from '../../assets/images/assets/upload.png';
import './pic-in-product.scss';


const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Tắt ảnh phóng to
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Bật ảnh phóng to
    </div>
  );
};

class PicInProduct extends React.Component {
  constructor(props) {
    super(props);
  }

  removePic = (url) => {
    const { onChange, productId, pictures  } = this.props;
    onChange(productId, pictures.filter(picture => picture.url !== url));
  }
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  onDragEnd = (result) => {
    const { onChange, productId, pictures  } = this.props;
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      pictures,
      result.source.index,
      result.destination.index
    );

    onChange(productId, items);
  }

  renderPicture = (picture, isShowDelete = true) => {
    return (
      <Card
        className="card-picture"
      >
        {picture.type === 'image' ? 
          <img className="border" src={picture.url} width="100%" height="100%"/>
        : ''}
        {picture.type === 'video'  ? 
          <ReactPlayer 
            controls={!isShowDelete}
            muted={true}
            playsinline={true}
            volume={0}
            loop={false}
            width="100%"
            height="100%"
            url={picture.url}
          />
        : ''}
        {isShowDelete ? 
          <div className="position-absolute">
          <Button onClick={() => this.removePic(picture.url)} className="btn-link btn-danger" style={{ background: 'none', border: 'none'}}><i className="text-danger fa fa-trash"></i></Button>
        </div>       
        : null}
         
      </Card>
    )
  }

  
  render(){
    const { productId, pictures, onOpenUpload } = this.props;
    return (
      <div>
        <div>
          <ImageGallery
          
            items={pictures.map(picture => ({
              renderItem: () => this.renderPicture(picture, false)
            }))}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={`droppable-${productId}`} direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className="list-pictures"
                {...provided.droppableProps}
              >
                {pictures.map((picture, index) =>
                    <Draggable key={`${productId}-${picture.url}`} draggableId={picture.url} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {this.renderPicture(picture)}
                      </div>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
                <Card className="card-picture" onClick={() => onOpenUpload(productId)}><img src={picUpload}/></Card>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

export default PicInProduct