import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"
import avatar8 from "../../assets/images/users/avatar-8.jpg"

const tasks = [
  {
    id: 1,
    title: "Đơn hàng vừa đặt",
    tasks: [
      {
        id: 11,
        description: "Create a Qovex Dashboard UI",
        name:'Nguyễn Văn Linh',
        date:'14-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
      {
        id: 12,
        description: "Create a Qovex Dashboard UI",
        name:'Hồ Tri Kỷ',
        date:'13-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
    ],
  },
  {
    id: 2,
    title: "Đã xác thực",
    tasks: [
      {
        id: 21,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhất',
        date:'13-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
      {
        id: 22,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhì',
        date:'12-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
    ],
  },
  {
    id: 3,
    title: "Đang chuẩn bị hàng",
    tasks: [
      {
        id: 31,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhất',
        date:'13-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
      {
        id: 32,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhì',
        date:'12-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
      {
        id: 33,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhì',
        date:'12-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
    ],
  },
  {
    id: 4,
    title: "Chuẩn bị vận chuyển",
    tasks: [
      {
        id: 41,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhất',
        date:'13-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
      {
        id: 42,
        description: "Create a Qovex Dashboard UI",
        name: 'Đinh văn Nhì',
        date:'12-07-2021',
        members: [
          {
            username: "",
            userImg: avatar2,
          },
          {
            username: "",
            userImg: avatar1,
          },
        ],
        budget: "145,000đ",
      },
    ],
  },
]

const series = [
  {
    name: "Complete Tasks",
    type: "column",
    data: [23, 11, 22, 27, 13, 22, 52, 21, 44, 22, 30],
  },
  {
    name: "All Tasks",
    type: "line",
    data: [23, 11, 34, 27, 17, 22, 62, 32, 44, 22, 39],
  },
]

const options = {
  chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
  stroke: { width: [0, 2, 5], curve: "smooth" },
  plotOptions: { bar: { columnWidth: "20%", endingShape: "rounded" } },
  colors: ["#556ee6", "#34c38f"],
  fill: {
    gradient: {
      inverseColors: !1,
      shade: "light",
      type: "vertical",
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100],
    },
  },
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
  ],
  markers: { size: 0 },
  yaxis: { min: 0 },
}

const statusClasses = {
  waiting: "badge-soft-secondary",
  approved: "badge-soft-primary",
  complete: "badge-soft-success",
  pending: "badge-soft-warning",
}

export { tasks, series, options, statusClasses }
