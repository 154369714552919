import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getPublic } from "../../helpers/api_helper";

const LocationViettelProvider = ({ children }) => {
  const { watch, setValue } = useFormContext();
  const [provinces, setProvinces] = useState();
  const [districts, setDistricts] = useState();
  const [wards, setWards] = useState();
  const [provincesState, setProvincesState] = useState();
  const [districtsState, setDistrictsState] = useState();
  const provincesCurrent = useRef();
  const districtCurrent = useRef();

  // =============Value selected=============
  const provinceValue = watch("province");
  const districtValue = watch("district");
  useEffect(() => {
    getPublic("/addresses/viettel-post-province").then((res) => {
      setProvinces(res.data);
    });
  }, []);

  // Provinces Dropdown Options
  const provinceOptions = useMemo(
    () =>
      provinces?.map((val) => ({
        label: val.name,
        value: val.id,
      })),
    [provinces]
  );

  // Districts Dropdown Options
  const districtOptions = useMemo(
    () =>
      districts?.map((val) => ({
        label: val.name,
        value: val.id,
      })),
    [districts]
  );

  // Wards Dropdown Options
  const wardOptions = useMemo(
    () =>
      wards?.map((val) => ({
        label: val.name,
        value: val.id,
      })),
    [wards]
  );

  // LISTENER OPTIONS CHANGE
  useEffect(() => {
    setProvincesState(provinceValue);
    setDistricts([]);
    setWards([]);
  }, [provinceValue]);

  useEffect(() => {
    setDistrictsState(districtValue);
  }, [districtValue]);


  // UPDATE OPTIONS
  // update: --- districts options
  useEffect(() => {
    if (provincesState) {
      if (!provincesCurrent.current) {
        provincesCurrent.current = provincesState;
      }
      if (provincesCurrent.current?.value !== provincesState.value) {
        provincesCurrent.current = provincesState;
        setValue("district", null);
        setValue("ward", null);
      }
      getPublic(`/addresses/viettel-post-district?provinceId=${provincesState.value}`).then(
        (res) => {
          setDistricts(res.data);
        }
      );
    }
  }, [provincesState]);

  // update: --- wards options
  useEffect(() => {
    if (districtsState) {
      if (!districtCurrent.current) {
        districtCurrent.current = districtsState;
      }
      if (districtCurrent.current?.value !== districtsState.value) {
        districtCurrent.current = districtsState;
        setValue("ward", null);
      }
      getPublic(`/addresses/viettel-post-ward?districtId=${districtsState.value}`).then(
        (res) => {
          setWards(res.data);
        }
      );
    }
  }, [districtsState]);

  return (
    <>
      {children({
        provinceOptions,
        districtOptions,
        wardOptions,
      })}
    </>
  );
};
export default LocationViettelProvider;
