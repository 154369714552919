import { useRef, useState, useEffect } from 'react';
import "./CKEditor.scss";
const CKEditorComponent = ({
  data,
  onChange,
  ...restProps
}) => {
  const editorRef = useRef()
  const [editorLoaded, setEditorLoaded] = useState(false)
  const { CKEditor, ClassicEditor } = editorRef.current || {}

  useEffect(() => {
    const { CKEditor } = require('@ckeditor/ckeditor5-react');
    editorRef.current = {
      CKEditor: CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    }
    setEditorLoaded(true)
  }, []);

  return editorLoaded ? <CKEditor
    onReady={(editor) => {
      editor.focus();
      editor.model.change((writer) => {
        writer.setSelection(
          writer.createPositionAt(
            editor.model.document.getRoot(),
            "end"
          )
        );
      });
    }}
    config={{
      toolbar: {
        items: [
          'heading',
          '|',
          'fontSize',
          'fontFamily',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'highlight',
          '|',
          'alignment',
          '|',
          'numberedList',
          'bulletedList',
          '|',
          'indent',
          'outdent',
          '|',
          'todoList',
          'link',
          'blockQuote',
          'imageUpload',
          'insertTable',
          '|',
          'undo',
          'redo'
        ]
      },
    }}
    editor={ClassicEditor}
    onChange={onChange}
    data={data}
    {...restProps}
  /> : <>Loading</>
}

export default CKEditorComponent;