import React from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Row,
} from "reactstrap";
import ScanInventory from './scan-inventory';

const Scan = () => {

  return <div className="page-content page-product">
    <Breadcrumbs title="Đơn hàng" breadcrumbItem="Quét hoàn thành" />
    <Row className="scan-inventory">
      <Col md={12} xs={12}>
        <ScanInventory
          col={2}
          titleClass="text-warning"
          type="FINISH_TIME"
        />
      </Col>
    </Row>

  </div>
}

export default Scan