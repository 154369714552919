import React, { useState, useEffect, useRef } from "react";
import OderItem from "./oderItem";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button } from "reactstrap";
import ComponentToPrintAll from "./printComponentAll";
import ReactToPrint from "react-to-print";
import { get } from "../../helpers/api_helper";
import "./index.scss";

const ListDetailOder = (props) => {
  const [list, setList] = useState([]);
  const printAllRef = useRef(null);

  useEffect(() => {
    get(`/bills/list?offset=0&limit=10000&status=PREPARING`).then((res) => {
      setList(res.list);
    });
  }, []);

  return (
    <div className="p-listDetailOder">
      <Breadcrumbs title="Đơn hàng" breadcrumbItem="danh sách hàng" />
      {list && list.length > 0 ? (
        <>
          <div className="p-listDetailOder_wrapButton">
            <ReactToPrint
              trigger={() => (
                <Button
                  style={{ marginRight: 5 }}
                  className="btn btn-primary waves-effect waves-light"
                >
                  In tất cả
                </Button>
              )}
              content={() => printAllRef.current}
            />
          </div>
          <div className="p-listDetailOder_list">
            {list.map((item, index) => (
              <div key={index} className="p-listDetailOder_item">
                <OderItem
                  name={item.name}
                  phone={item.phone}
                  email={item.email}
                  id={item.id}
                  province={item.Province.name}
                  district={item.District.name}
                  ward={item.Ward.name}
                  street={item.addressDeliver.street}
                  listProduct={item.BillDetails}
                  createdAt={item.createdAt}
                  total={item.total}
                  productsAmount={item.productsAmount}
                  vatAmount={item.vatAmount}
                  costDeliver={item.costDeliver}
                  note={item.note}
                  noteInvoice={item.noteInvoice}
                  invoiceType={item.invoiceType}
                  vatPercent={item.vatPercent}
                  deposit={item.deposit}
                  typeDeliver={item.typeDeliver}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="p-listDetailOder_text">Không có đơn hàng</p>
      )}
      <div style={{ display: "none" }}>
        <ComponentToPrintAll ref={printAllRef} listProduct={list} />
      </div>
    </div>
  );
};

export default ListDetailOder;
