import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const ModalConfirm = (props) => {
  const { isOpen, title, desc, handleAccept, handleCancel, isLoading } = props;
  return (
    <Modal isOpen={isOpen} toggle={handleCancel} centered={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody align="center">
        <h5>{desc}</h5>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!!isLoading} className="btn-danger mr-3" onClick={handleAccept}>
          Có
        </Button>
        <Button disabled={!!isLoading} className="btn-secondary mr-3" onClick={handleCancel}>
          Huỷ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  handleAccept: PropTypes.func,
  handleCancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ModalConfirm;
