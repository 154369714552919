import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { RenderAmount } from "../../utils";
import ComponentToPrintOne from "./printComponentOne";

const ComponentToPrintAll = React.forwardRef((props, ref) => {
  const { listProduct } = props;

  const totalQuantity = (data) => {
    let totalQuantity = 0;
    data.map((item) => {
      totalQuantity += item.quantity
    })
    return totalQuantity
  }
  return (
    <div ref={ref} style={{ padding: "20px 20px 20px 30px" }}>
      {listProduct &&
        listProduct.map((item, index) => (
          <div key={index} style={{ marginTop: 40, pageBreakAfter: "always" }}>
            <ComponentToPrintOne
              name={item.name}
              phone={item.phone}
              email={item.email}
              id={item.id}
              province={item.Province.name}
              district={item.District.name}
              ward={item.Ward.name}
              street={item.addressDeliver.street}
              listProduct={item.BillDetails}
              createdAt={item.createdAt}
              total={item.total}
              productsAmount={item.productsAmount}
              vatAmount={item.vatAmount}
              costDeliver={item.costDeliver}
              note={item.note}
              noteInvoice={item.noteInvoice}
              invoiceType={item.invoiceType}
              vatPercent={item.vatPercent}
              deposit={item.deposit}
              typeDeliver={item.typeDeliver}
            />
          </div>
        ))}
    </div>
  );
});

export default ComponentToPrintAll;

ComponentToPrintAll.propTypes = {
  listProduct: PropTypes.array,
};
