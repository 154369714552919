import React from "react"
import PropTypes from "prop-types"
import { Route, Navigate } from "react-router"

const Authmiddleware = ({
  children,
}) => {
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate
        to={"/login"}
      />
    )
  }
  return children;
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
