import { useEffect, useState } from "react";
import Select from "react-select";

import {
  CardTitle,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import InfiniteScroll from "react-infinite-scroll-component";
import { get, getPublic, put } from "../../helpers/api_helper";
import toastr from "toastr";

import "react-virtualized/styles.css";
import "./index.scss";


const CategoryProducts = (props) => {
  const [categoryObjs, setCategoryObjs] = useState({});
  const [listOptionCategory, setListOptionCategory] = useState([]);
  const [productObj, setProductObj] = useState({});

  useEffect(() => {
    get(`/categories/list`)
    .then((data) => {
      setListOptionCategory(data.map(e => ({
        value: e.id,
        label: e.name
      })))
      setCategoryObjs(
        data.reduce((prev, cur) => ({
          ...prev,
          [cur.id]: cur
        }), {})
      )
    })
  }, []);
  const onSelectCate = (cate) => {
    if (productObj[`c-${cate.id}`]) {
      const temp = {...productObj}
      delete temp[`c-${cate.id}`];
      setProductObj(temp)
      return;
    }

    setProductObj({
      ...productObj,
      [`c-${cate.id}`]: []
    })
    getPublic(`/products/by-category`, {
      params: {
        category: cate.slug,
        includeChild: false,
        limit: 10000,
        offset: 0
      }
    })
      .then(({ data }) => {
        setProductObj({
          ...productObj,
          [`c-${cate.id}`]: data.rows.map(e => ({
            id: e.id,
            name: e.name
          }))
        })
      })
  }

  const changeCateOfProduct = (product, newCate, oldCate) => {
    if (newCate.value === oldCate.id) {
      return;
    }
    put(`/products/update-category`, {
      productId: product.id,
      categoryId: newCate.value
    }, {})
      .then(() => {
        const temp = {...productObj}
        temp[`c-${oldCate.id}`] = temp[`c-${oldCate.id}`].filter((pTemp) => pTemp.id !== product.id)
        if( temp[`c-${newCate.value}`]) {
          temp[`c-${newCate.value}`].push(product)
        }
        setProductObj(temp)
        toastr.success('Thay đổi danh mục thành công')

      })
      .catch(({ error}) => {
        toastr.error(error)
      })
  }
  return (
    <>
      <div className="page-content page-product-category">
        <Breadcrumbs title="Sản phẩm" breadcrumbItem="danh sách sản phẩm" />
        <Row className="data-content">
          <Col md={4} lg={3} className="left-list-main">
            <div className="left-list-products" id="scroll-infinite">
              <InfiniteScroll
                scrollableTarget="scroll-infinite"
                dataLength={Object.keys(categoryObjs).length}
                next={() => {}}
                hasMore={false}
              >
                {Object.values(categoryObjs).map((cate) => (
                  <Card
                    key={`left-${cate.id}`}
                    outline
                    className="border mb-1 point-click"
                    style={{
                      ...(!!productObj[`c-${cate.id}`]
                        ? { opacity: 0.2 }
                        : {}),
                    }}
                    onClick={() => {
                      onSelectCate(cate);
                    }}
                  >
                    <Row className="g-0 align-items-center">
                      <Col xs={12}>
                        <CardBody className="p-1">
                          <CardTitle className="">
                              {cate.name}
                          </CardTitle>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </InfiniteScroll>
            </div>
          </Col>
          <Col md={8} lg={9} className="left-list-main">
            <div className="left-list-products">
              {Object.entries(productObj).map(([key, list]) =>
                <Card key={key} outline={true} >
                  <CardHeader>
                    <CardTitle><span>{categoryObjs[key.slice(2)].parentId ? `${categoryObjs[key.slice(2)]?.ParentCategory?.name} -> ` : ''}{categoryObjs[key.slice(2)]?.name}</span></CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                    {
                      list.map((product) => 
                        <Col md={6} >
                          <Card style={{ border: '1px solid gray'}}>
                            <CardBody>
                              <span className="product-name">
                                  {product?.name}
                                </span>
                              <Select
                                value={{
                                  value: categoryObjs[key.slice(2)]?.id,
                                  label: categoryObjs[key.slice(2)]?.name,
                                }}
                                onChange={(option) => changeCateOfProduct(product, option, categoryObjs[key.slice(2)])}
                                options={listOptionCategory}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    }
                    </Row>

                  </CardBody>
                </Card>
              )}
            </div>

          </Col>
        </Row>
      </div>
    </>
  );
};

export default CategoryProducts;
