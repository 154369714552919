import { useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row, Table, CardImg, Button } from "reactstrap"
import { get } from "../../helpers/api_helper";
import Select from "react-select";
import slugify from "slugify";

const ProductRepresentative = ({
  onClose,
  onSave,
  category
}) => {
  const [productRepresentative, setProductRepresentative] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    get(`/products/list?offset=0&limit=10000&categoryId=${category.id}`).then((data) => {
      const products = data.list.map(e => ({
        label: e.name,
        value: e.id,
        data: {
          ...e,
          picture: e.picture.find((p) => p.type === "image")?.url
        },
      }))
      const productIds = data.list.map(e => e.id);

      let productRepresentative = category.productRepresentative ? category.productRepresentative.split(',').map(Number) : [];
      console.log(productRepresentative, productIds)
      productRepresentative = productRepresentative.filter(e => productIds.includes(e)).map(e => ({
        id: e,
        data: products[productIds.indexOf(e)].data
      }))
      setProductRepresentative(productRepresentative);
      setProducts(products);
    });
  }, [])
  const handleSave = () => {
    const data  = [...new Set(productRepresentative.map(e => e.id))].join(',')
    onSave(category, data)
  }

  const onDeleteProductRepresentative = (i) => {
    const temp = [...productRepresentative];
    temp.splice(i, 1)
    setProductRepresentative(temp);
  }


  const customFilter = (option, searchText) => {
    const isSelected = productRepresentative.map(e => e.id).includes(option.value)
    if (searchText === '') {
      return !isSelected
    }
    return slugify(option.label).includes(slugify(searchText)) && !isSelected

  }

  const onAdd = () => {
    setProductRepresentative([
      {
        id: selectedProduct.value,
        data: selectedProduct.data
      },
      ...productRepresentative
    ]);

  }

  return <Modal isOpen={true} toggle={onClose} centered={true} size="lg">
    <ModalBody className="py-3 px-5">
      <Row>
        <Col lg={12}>
          <div className="text-center">
            <div className="table-responsive">
              <Table>
                <thead>
                  <td>
                    Hình
                  </td>
                  <td>
                    Tên
                  </td>
                  <td>
                    Xoá
                  </td>
                </thead>
                <tbody>
                  {productRepresentative.map((e, i) =>
                    <tr>
                      <td>
                        <CardImg  className="img-fluid" style={{width: 50, height: 50}} src={e.data.picture} />
                      </td>
                      <td>
                        {e.data.name}
                      </td>
                      <td>
                        <Button className="btn-danger" onClick={() => onDeleteProductRepresentative(i)}><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
       
      </Row>
      <Row>
        <Col sm={9}>
          <Select
            value={selectedProduct}
            onChange={(option) => setSelectedProduct(option)}
            placeholder="Chọn sản phẩm"
            noOptionsMessage={() => "Không tìm thấy"}
            filterOption={customFilter}

            options={products}
            classNamePrefix="select2-selection"
          />
        </Col>
        <Col sm={3}>
          <Button className="btn-success" disabled={!selectedProduct} onClick={onAdd}>Thêm</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center mt-3">
            <button
              type="button"
              className="btn btn-success btn-lg me-2"
              onClick={handleSave}
            >
              Lưu
            </button>
            <button
              type="button"
              className="btn btn-danger btn-lg me-2"
              onClick={onClose}
            >
              Huỷ
            </button>
          </div>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
}

export default ProductRepresentative;