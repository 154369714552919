import { useEffect, useState, useRef } from 'react';
import { get, post, del, put } from '../../helpers/api_helper';
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalBody, ModalHeader, ModalFooter, Table, Input } from "reactstrap"
import Select from "react-select";
import toastr from 'toastr';
import { NumberInput } from "../../utils";

import Breadcrumbs from "../../components/Common/Breadcrumb"
import './index.scss';
const Category = props => {
  const [list, setList] = useState([]);
  const [provinceSelected, setProvinceSelected] = useState(null);
  const [districtSelected, setDistrictSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    get(`/delivery/list`)
      .then((data) => {
        setList(
          data
            .sort((a, b) => (b.provinceId === 79 ? 1 : 0) - (a.provinceId === 79 ? 1 : 0))
            .map((e, i) => ({
              ...e,
              index: i
            }))
        )
      })
  }, [])

  const onChange = (index, key, value) => {
    const temp = [...list];
    list[index][key] = value;
    setList(temp);
  }
  const onSave = () => {
    setLoading(true);
    put(`/delivery/update`, {
      items: list.map((e) => {
        const {
          districtId,
          provinceId,
          wardId,
          estimateDeliveryCostNormal,
          estimateDeliveryCostFaster,
          estimateDeliveryOverCostFaster,
          estimateDeliveryOverCostNormal,
          rangeFreeShip,
        } = e;
        return {
          districtId,
          provinceId,
          wardId,
          estimateDeliveryCostNormal,
          estimateDeliveryCostFaster,
          estimateDeliveryOverCostFaster,
          estimateDeliveryOverCostNormal,
          ...(rangeFreeShip !== null ? { rangeFreeShip: +rangeFreeShip }: {}),
        }
      })
    })
      .then(() => {
        toastr.success('Cập nhật thành công thành công');
      })
      .catch((err) => {
        toastr.error(err.error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const listProvinces = () => {
    const obj = {};
    list.forEach((e) => {
      obj[`p-${e.provinceId}`] = {
        label: e.Province.name,
        value: e.Province.id
      }
    });
    return Object.values(obj);
  }
  const listDistricts = () => {
    const listDistrictByProvince = list.filter(e => e.provinceId === provinceSelected);
    if (listDistrictByProvince.length === 0) {
      return [];
    }
    const obj = {}
    listDistrictByProvince.forEach((e) => {
      obj[`d-${e.districtId}`] = {
        label: e.District.name,
        value: e.District.id
      }
    });
    return Object.values(obj);
  }

  return (
    <>
      <div className="page-content page-pricedelivery">
        <Breadcrumbs title="Danh mục" breadcrumbItem="Danh sách danh mục" />
        <Row>
          <Col md={12} xs={12}>
            <Card className="list-category">
              <CardBody>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th width="20%">
                          <Select
                            placeholder="Lọc theo thành phố"
                            options={listProvinces()}
                            isClearable={true}
                            onChange={(value) => {
                              setProvinceSelected(value?.value);
                              setDistrictSelected(null);
                            }}
                          />
                            {
                              provinceSelected 
                              ? 
                                <Select
                                placeholder="Lọc theo quận/huyên"
                                options={listDistricts()}
                                isClearable={true}
                                onChange={(value) => {
                                  setDistrictSelected(value?.value);
                                }}
                              />
                              : ''
                            }
                        </th>
                        <th width="25%">
                          VC Chậm
                        </th>
                        <th width="25%">
                          VC Nhanh
                        </th>
                        <th width="20%">
                          Ngưỡng miễn ship
                        </th>
                        <th width="10%">
                          <Button disabled={loading} className="btn-success" onClick={() => onSave()}><i className="fa fa-save"></i> Lưu tất cả</Button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {((provinceSelected || districtSelected) ? list.filter((e) => e.provinceId === provinceSelected && (!districtSelected || (districtSelected === e.districtId))) : list).map((deli) =>
                        <tr key={deli.id}>
                          <td>
                            {deli?.Ward?.name ? <>{deli?.Ward?.name}<br /></> : ''}

                            {deli.District.name} <br />
                            {deli.Province.name} 
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span style={{ width: '90px' }}>{"< 1 ký: "} </span>
                              <NumberInput
                                value={deli.estimateDeliveryCostNormal}
                                onChange={(e) => onChange(deli.index, 'estimateDeliveryCostNormal', e.value)}
                                customInput={Input}
                                suffix={" đ"}
                                decimalScale={0}
                              />
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <span style={{ width: '90px' }}>{"1 ký quá: "}</span>
                              <NumberInput
                                onChange={(e) => onChange(deli.index, 'estimateDeliveryOverCostNormal', e.value)}
                                customInput={Input}
                                suffix={" đ"}
                                value={deli.estimateDeliveryOverCostNormal}
                                decimalScale={0}
                              />
                            </div>
                          </td>
                          <td className="">
                            <div className="d-flex align-items-center">
                              <span style={{ width: '90px' }}>{"< 1 ký: "} </span>
                              <NumberInput
                                value={deli.estimateDeliveryCostFaster}
                                onChange={(e) => onChange(deli.index, 'estimateDeliveryCostFaster', e.value)}
                                customInput={Input}
                                suffix={" đ"}
                                decimalScale={0}
                              />
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <span style={{ width: '90px' }}>{"1 ký quá: "}</span>
                              <NumberInput
                                value={deli.estimateDeliveryOverCostFaster}
                                onChange={(e) => onChange(deli.index, 'estimateDeliveryOverCostFaster', e.value)}
                                customInput={Input}
                                suffix={" đ"}
                                decimalScale={0}
                              />
                            </div>
                          </td>
                          <td colSpan={2}>
                            <NumberInput
                              placeholder='Ngưỡng miễn phí vận chuyển'
                              value={deli.rangeFreeShip}
                              onChange={(e) => onChange(deli.index, 'rangeFreeShip', e.value)}
                              customInput={Input}
                              suffix={" đ"}
                              decimalScale={0}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Category
