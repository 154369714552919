import React from "react"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router"
import { Provider } from "react-redux"
import store from "./store"
import { createRoot } from 'react-dom/client';

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);
serviceWorker.unregister()
