import Dropzone from 'react-dropzone-uploader';
import ReactPlayer from 'react-player';
import { Progress,  Badge } from 'reactstrap';
import { API_URL } from '../../helpers/api_helper';
import { acceptedVideo, acceptedImage } from '../../utils';
import accessToken from "../../helpers/jwt-token-access/accessToken"
const DropzoneCustomize = ({ onUploadSuccess, urlUpload, ...props }) => {
  const preview = ({ meta, fileWithMeta }) => {
    const { percent, status, type } = meta;
    let renderStatus = '';
    switch (status) {
      case 'done':
        renderStatus = 'Thành công';
        break;
      case 'getting_upload_params':
        renderStatus = 'Tiến hành upload';
        break;
      case 'uploading':
        renderStatus = 'Đang upload';
        break;
      case 'preparing':
      default:
        renderStatus = 'Đang chuẩn bị';
    }
    return (
      <>
        <div>
          <div className="position-relative">
            <div style={{ float: 'left'}}>
                {acceptedImage.includes(type) ? 
                  <img className="border" width={150} height={150} src={URL.createObjectURL(fileWithMeta.file)}/>
                : ''}
                {acceptedVideo.includes(type) ? 
                    <ReactPlayer 
                      controls
                      muted={true}
                      playsinline={true}
                      volume={0}
                      playing={false}
                      loop={false}
                      width={150} height={150}
                      config={{ file: { 
                        attributes: {
                          controlsList: 'nodownload noremoteplayback noplaybackrate'
                        }
                      }}}
                      url={URL.createObjectURL(fileWithMeta.file)}
                  />
                : ''}
            </div>
            <div style={{ float: 'right', width: '200px', textAlign: 'right'}}>
              {status === 'done' ? 
              <Badge pill className={`rounded-pill badge-soft-success`} >{renderStatus}</Badge>
              : <Progress  color='primary' value={percent}/>
              }
              
            </div>
          </div>
        </div>
        <div style={{ clear: 'both' }} className="mb-3"></div>
      </>
    )
  }

  const getUploadParams = ({ meta }) => {
    let url = '';
    if (urlUpload) {
      url = urlUpload(meta)
    } else {
      if (acceptedImage.includes(meta.type)) {
        url = '/products/upload-picture';
      }
      if (acceptedVideo.includes(meta.type)) {
        url = '/products/upload-video';
      }
    }

    return { url: `${API_URL}${url}`, headers: {
      'authentication': accessToken()
    } } 
  }
  
  const handleChangeStatus = ({ xhr, meta: { type } }, status) => {
    if (status === 'done'){
      onUploadSuccess(xhr.response, type)
    }  

  }

  return (
    <Dropzone
      multiple={true}
      accept={[...acceptedVideo, ...acceptedImage].join(',')}
      classNames={{
        dropzone: 'dropzone-picture'
      }}
      PreviewComponent={preview}
      inputContent='Kéo thả ảnh/phim hoặc bấm vào để chọn'
      inputWithFilesContent="Thêm ảnh/phim "
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      {...props}
    />
  )
}

export default DropzoneCustomize