import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
export const API_URL_PUBLIC = process.env.REACT_APP_API_URL;
export const API_URL = `${API_URL_PUBLIC}/admin`
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
})

const axiosApiPublic = axios.create({
  baseURL: API_URL_PUBLIC,
})

export const setToken = () => axiosApi.defaults.headers.common["authentication"] = accessToken()
setToken()

axiosApi.interceptors.response.use(
  response => response?.data,
  error => {
    if (error?.response?.status === 401 ) {
      if ( window.location.pathname !== '/login') {
        localStorage.clear();
        window.location.href = '/login'
      }
    }
    return Promise.reject(error?.response?.data)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
}

export async function getPublic(url, config = {}) {
  return await axiosApiPublic.get(url, { ...config })
}

export async function postPublic(url, config = {}) {
  return await axiosApiPublic.post(url, { ...config })
}
