import PropTypes from 'prop-types'
import React from "react"

import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"


import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
fakeBackend()

const App = (props) => {
  const getLayout = () => {

    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>

      <Routes>
        <Route element={<NonAuthLayout />}>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route element={<Authmiddleware><Layout /></Authmiddleware>}>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <route.component />
              }
            />

          ))}
        </Route>
        <Route path="/" element={<Navigate to="/status-oder" />} />

      </Routes>

    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)