import React, { useState, useEffect } from "react"
import { Row, Col, CardBody, Card, Progress, CardHeader } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactApexChart from "react-apexcharts"
import { get, post } from '../../helpers/api_helper';
import toastr from "toastr";
import moment from 'moment';
const defaultOptionsActiveUsers = {
  chart: {
    type: 'line',
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false,
    }
  },
  colors: ['#77B6EA', '#af2045'],
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Lượng khách truy cập trong 28 ngày qua',
    align: 'left'
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5
    },
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [],
  },

  tooltip: {
    enabled: true,
  },

};

const defaultOptionsActiveByPath = {
  chart: {
    type: 'bar',
    toolbar: {
      show: false
    },
  },
  title: {
    text: 'Top 10 trang khách truy cập nhiều trong 28 ngày qua',
    align: 'left'
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
      dataLabels: {
        position: 'bottom'
      },
    }
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#000']
    },
    formatter: function (val, opt) {
      return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
    },
    offsetX: 0,
    dropShadow: {
      enabled: false
    }
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    labels: {
      show: false
    }
  },
  tooltip: {
    enabled: false,
  },
};

const Dashboard = () => {
  const [reportActiveUsers, setReportActiveUsers] = useState(null);
  const [reportActiveByPath, setReportActiveByPath] = useState(null);
  const [reportRealtimeUsers, setReportRealtimeUsers] = useState(null);
  const [reportRealtimeDevice, setReportRealtimeDevice] = useState(null);

  const renderPropsCharActiveUsers = (reportActiveUser) => {
    const options = { ...defaultOptionsActiveUsers }
    const series = [
      {
        name: "Khách truy cập",
        data: []
      },
      {
        name: "Khách mới truy cập",
        data: []
      }
    ];
    reportActiveUser.rows.map(({ dimensionValues, metricValues }) => {
      const [{ value: date }] = dimensionValues;
      const [{ value: activeUser }, { value: newUser }] = metricValues;
      options.xaxis.categories.unshift(moment(date, 'YYYYMMDD').format('DD/MM/YY'))
      series[0].data.unshift(activeUser)
      series[1].data.unshift(newUser)
    })
    return {
      options,
      series,
      type: 'line'
    }
  }

  const renderPropsCharActiveByPath = (reportByPath) => {
    const options = { ...defaultOptionsActiveByPath };
    const series = [
      {
        data: []
      },
    ];

    reportByPath.rows.map(({ dimensionValues, metricValues }) => {
      const [{ value: pageTitle }] = dimensionValues;
      const [{ value: screenPageViews }] = metricValues;
      options.xaxis.categories.push(pageTitle)
      series[0].data.push(screenPageViews)
    })
    return {
      options,
      series,
      type: 'bar'
    }

  }

  useEffect(() => {
    get(`/report/active-users`)
      .then(({
        reportActiveUser,
        reportByPath
      }) => {
        setReportActiveUsers(renderPropsCharActiveUsers(reportActiveUser))
        setReportActiveByPath(renderPropsCharActiveByPath(reportByPath))
      })
      .catch((err) => {
        toastr.error(err);
      });
  }, [])
  const fetchrealTime = () => {
    get(`/report/realtime-users`)
      .then(({
        reportActiveUser,
        reportActivePlatform
      }) => {
        setReportRealtimeUsers(reportActiveUser.rows?.[0]?.metricValues[0].value || 0);
        const devices = reportActivePlatform.rows.map(({ dimensionValues, metricValues }) => {
          return {
            title: dimensionValues[0].value,
            value: metricValues[0].value
          }
        })
        setReportRealtimeDevice(devices);
      })
  }
  useEffect(() => {
    fetchrealTime();
    const interval = setInterval(fetchrealTime, 10000);
    return () => clearInterval(interval);
  }, [])
  return (
    <div className="page-content p-0">
      <Breadcrumbs title="Thống kê truy cập" breadcrumbItem="Thống kê truy cập" />
      <div style={{
        height: 'calc(100vh - 200px)',
        overflowY: 'auto',
      }}>
        <Row className="m-0">
          <Col md={3} sm={4}>
            <Card>
              <CardHeader>
                <h5>Số khách đang truy cập trong 30 phút</h5>
              </CardHeader>
              <CardBody>
              { reportRealtimeUsers !== null
                ? `${reportRealtimeUsers} khách`
                : 'Đang tải thông tin' }
              </CardBody>
            </Card>
          </Col> 

          <Col md={3} sm={4}>
            <Card>
              <CardHeader>
                <h5>Thiết bị đang truy cập trong 30 phút</h5>
              </CardHeader>
              <CardBody>
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        Tên thiết bị
                      </th>
                      <th>Lượng truy cập</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      reportRealtimeDevice !== null ? reportRealtimeDevice.map((device, i) =>
                        <tr key={i}>
                          <td>
                            {device.title}
                          </td>
                          <td>
                            {device.value}
                          </td>
                        </tr>
                      ) : <tr><td colSpan={2}>Đang tải thông tin</td></tr>
                    }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>

        </Row>
        <Row className="m-0">
        <Col md={6} sm={12}>
            <Card>
              <CardBody style={{ minHeight: 300 }}>
              { reportActiveUsers !== null 
                ? <ReactApexChart height={'100%'} {...reportActiveUsers} />
                : 'Đang tải thông tin' }
              </CardBody>
            </Card>
          </Col>
          <Col md={6} sm={12}>
            <Card>
              <CardBody  style={{ minHeight: 400 }}>
                {reportActiveByPath !== null 
                  ? <ReactApexChart height={'100%'}  {...reportActiveByPath} />
                  : 'Đang tải thông tin'}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </div>
  )
}

export default Dashboard