import React, { useEffect, useState, useRef } from "react";
import { get, post } from "../../helpers/api_helper";
import moment from 'moment'
import BoopButton from './PlaySound';
import PlaySoundTing from './PlaySoundTing';
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import './scan-inventory.scss';
import { TIME_FIELD_INVENTORY, TIME_FIELD_TITLE_INVENTORY } from '../../constants';

const ScanInventory = ({ type, col = 2, titleClass = '' }) => {
  const title = TIME_FIELD_TITLE_INVENTORY[type];
  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 1000);

    getCountScanInventory();
    setInterval(() => {
      getCountScanInventory();
    }, 5000)
  }, [])
  const clearSortTyping = useRef();
  const playSoundRef = useRef();
  const playSoundTingRef = useRef();
  const inputRef = useRef();
  const [billId, setBillId] = useState('');
  const [history, setHistory] = useState([]);
  const [countScanInventory, setCountScanInventory] = useState({});


  const getCountScanInventory = () => {
    get(`/bills/count-scan-inventory`, {}, {})
      .then((result) => {
        setCountScanInventory(result)
      })
  }

  const save = (id) => {
    if (id === '') {
      return;
    }
    setBillId('');
    clearTimeout(clearSortTyping.current);
    clearSortTyping.current = setTimeout(() => {
      post(`/bills/scan-inventory/${id}`, {
        type
      }, {})
        .then(() => {
          playSoundTingRef.current.click()
          const temp = [...history];
          temp.unshift({
            billId: id,
            status: 1,
            message: `${moment().format('DD-MM-YYYY HH:mm:ss')}`
          })
          setHistory(temp)
        })
        .catch(e => {
          playSoundRef.current.click()
          const temp = [...history];
          temp.unshift({
            billId: id,
            status: 0,
            message: e.error
          })
          setHistory(temp)
        })
    }, 100);
  }

  return <>
    <div >
      <Row className="scan-inventory d-flex justify-content-center">
        <Col md={3} xs={12}>
        <ul>
                <li>
                  <b>{TIME_FIELD_TITLE_INVENTORY['PREPARING']}</b>: {countScanInventory[TIME_FIELD_INVENTORY['PREPARING']]} đơn
                </li>
                <li>
                  <b>{TIME_FIELD_TITLE_INVENTORY['CHECK_PACKAGE']}</b>: {countScanInventory[TIME_FIELD_INVENTORY['CHECK_PACKAGE']]} đơn
                </li>
                <li>
                  <b>{TIME_FIELD_TITLE_INVENTORY['IN_PACKAGE']}</b>: {countScanInventory[TIME_FIELD_INVENTORY['IN_PACKAGE']]} đơn
                </li>
              </ul>
        </Col>
        <Col md={6} xs={12}>
          <div className="scan-inventory_input">
            <h4 className={titleClass}>{title}</h4>
            <input
              autoFocus
              ref={inputRef}
              // disabled={loading}
              value={billId}
              onChange={(e) => setBillId(e.target.value)}
              onKeyUp={(event) => {
                const code = event.code;
                const key = event.keyCode || event.which
                if (code === "Enter" || code === "NumpadEnter" || key === '13') {
                  event.preventDefault();
                  save(billId);
                }
              }
              }
              onBlur={() => {
                inputRef?.current?.focus();
              }}
              placeholder="Nhập mã đơn hàng"
            />
            <span className="scan-inventory_input_icon" onClick={() => save(billId)}><i className="fa fa-search"></i></span>
          </div>
        </Col>
        <Col md={3} xs={0}></Col>
      </Row>
      <hr />
      <h2 className={titleClass}>Kết quả</h2>
      <div className="scan-inventory_history">
        <Row >
          {history.map((e, i) =>
            <Col key={i} md={12 / col}>
              <Card>
                <CardBody>
                  <h5 className={e.status ? titleClass : 'text-danger'}>{e.billId}</h5>
                  {e.status ? <strong className={titleClass}>{e.message}</strong> : ''}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </div>
    <BoopButton ref={playSoundRef} style={{ display: 'none' }} />
    <PlaySoundTing ref={playSoundTingRef} style={{ display: 'none' }} />

  </>
}

export default ScanInventory