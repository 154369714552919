import PropTypes from "prop-types";
import React from "react";
import { Button, Card } from "reactstrap";
import moment from 'moment'
import { RenderAmount } from "../../utils";
import "./oder-info.scss";

const OderInfo = (props) => {
  const {
    price,
    name,
    phone,
    district,
    ward,
    handleDetail,
    createdAt,
    cancelReason,
    status,
  } = props;

  return (
    <Card outline className="oder-info border mb-1 point-click">
      <div onClick={handleDetail}>
        <div className="oder-info_infoCustomer"><b className="text-info">{`${name} - ${phone}`}</b></div>
        <div className="oder-info_address text-warning">
          {moment(createdAt).format('HH:mm DD-MM-YYYY')}
        </div>
        <div className="oder-info_address">
          {ward && district ? `${ward?.name} - ${district?.name}` : "-"}
        </div>
        <div className="oder-info_totalPrice text-primary">
          {<RenderAmount price={price} />}
        </div>
        <hr className="m-2" />
        {status === 'CANCELED' ?
          <div className="oder-info_totalPrice text-danger">
          Lý do huỷ: {cancelReason || "Không có lý do"}
          </div>
          : null}
      </div>
    </Card>
  );
};

export default OderInfo;

OderInfo.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  district: PropTypes.any,
  ward: PropTypes.any,
  price: PropTypes.number,
  handleDetail: PropTypes.func,
  createdAt: PropTypes.string,
};
